// import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SlideShow from "../components/slider"
import Seo from "../components/seo"
import Blurb from "../components/blurb"
import { Row, Container } from "react-bootstrap"
import Header from "../components/header"
import CardBody from "../components/bodyCards"

export const query = graphql`
  {
    autoguardLogo: file(relativePath: { eq: "autoguard-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tornLeather: file(relativePath: { eq: "torn-leather.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bumperRepair: file(relativePath: { eq: "bumper-repair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doorDent: file(relativePath: { eq: "dent.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    scratch: file(relativePath: { eq: "scratch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Autoguard = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const tornLeather = data.tornLeather.childImageSharp.fluid
  const autoguardLogo = data.autoguardLogo.childImageSharp.fluid
  const doorDent = data.doorDent.childImageSharp.fluid
  const scratch = data.scratch.childImageSharp.fluid

  const content = [
    {
      title: "Interior Cuts and Tears",
      description: "Repair cuts up to 5cm in length.",
      image: `${tornLeather.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Door Dings and Dents",
      description: "Up to 10cm in length or width.",
      button: "Call Now",
      image: `${doorDent.src}`,
      phone: `${phoneNum}`,
    },
    {
      title: "Scratches or Scrapes",
      description: "Exterior scratches up to 20cm long.",
      image: `${scratch.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
  ]

  const text =
    "Everyday life happens. Sometimes that includes cuts, dents and scratches. With AutoGuard protection you can keep the new car look for years to come."
  const cardContent = {
    1: {
      img: data.bumperRepair.childImageSharp.fluid,
      alt: "Fix Scratches",
      list: [
        "We will repair scratches",
        "Scrapes are covered as well",
        "Door dings? Door dents? No problem",
      ],
      header: "We've got the outside of your vehicle covered.",
    },
    2: {
      img: data.tornLeather.childImageSharp.fluid,
      alt: "Fix Interior",
      list: ["Cuts and tears up to 5cm"],
      header: "When accidents happen, we have you covered.",
    },
  }
  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "autoguard" }}
    >
      <Seo
        title="Autoguard"
        keywords={[`protection`, `vehicle`, `secure`, `safe`]}
      />
      <SlideShow data={content} />
      <Header logo={autoguardLogo.src} />
      <Blurb text={text} />
      <Container fluid>
        <Row>
          <CardBody content={cardContent} />
        </Row>
      </Container>
    </Layout>
  )
}

export default Autoguard
